import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { class: "text-uppercase font-weight-semibold font-size-34 mr-3 text-break hyphenate title-small text-white" }
const _hoisted_2 = {
  class: "font-weight-bold flex-fill",
  style: {"min-width":"0"}
}
const _hoisted_3 = {
  class: "ion-text-end",
  style: {"min-width":"3.5rem"}
}
const _hoisted_4 = {
  key: 1,
  class: "fa-solid fa-building text-white font-size-38"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_b_col = _resolveComponent("b-col")!
  const _component_b_row = _resolveComponent("b-row")!
  const _component_b_tab = _resolveComponent("b-tab")!
  const _component_b_tabs = _resolveComponent("b-tabs")!
  const _component_b_overlay = _resolveComponent("b-overlay")!

  return (_openBlock(), _createBlock(_component_b_overlay, {
    show: _ctx.loading,
    class: "bg-color-eminence-500 radius-10 p-4 h-100"
  }, {
    default: _withCtx(() => [
      _createVNode(_component_b_row, null, {
        default: _withCtx(() => [
          _createVNode(_component_b_col, {
            sm: "12",
            class: "justify-content-end d-flex"
          }, {
            default: _withCtx(() => [
              _createElementVNode("span", _hoisted_1, _toDisplayString(_ctx.$t("general.price")), 1)
            ]),
            _: 1
          }),
          _createVNode(_component_b_col, { sm: "12" }, {
            default: _withCtx(() => [
              _createVNode(_component_b_tabs, {
                pills: "",
                "active-nav-item-class": "active-nav-item",
                class: "education-tabs eminence-500",
                "nav-wrapper-class": "mb-4",
                onActivateTab: _ctx.onChangeTab
              }, {
                default: _withCtx(() => [
                  (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.pricesByStudent, (prices) => {
                    return (_openBlock(), _createBlock(_component_b_tab, {
                      title: prices.licenseClass,
                      key: prices.licenseClass
                    }, {
                      default: _withCtx(() => [
                        (!_ctx.studentEducationRelatedToCostBearer(prices))
                          ? (_openBlock(true), _createElementBlock(_Fragment, { key: 0 }, _renderList(prices.prices, (price) => {
                              return (_openBlock(), _createBlock(_component_b_row, {
                                key: price.id
                              }, {
                                default: _withCtx(() => [
                                  _createVNode(_component_b_col, {
                                    cols: "7",
                                    class: "text-white font-size-18 form-group"
                                  }, {
                                    default: _withCtx(() => [
                                      _createElementVNode("span", _hoisted_2, _toDisplayString(price.product.name), 1)
                                    ]),
                                    _: 2
                                  }, 1024),
                                  _createVNode(_component_b_col, {
                                    cols: "5",
                                    class: "text-white font-size-18 form-group"
                                  }, {
                                    default: _withCtx(() => [
                                      _createElementVNode("span", _hoisted_3, _toDisplayString(_ctx.format(price.priceInEur)), 1)
                                    ]),
                                    _: 2
                                  }, 1024)
                                ]),
                                _: 2
                              }, 1024))
                            }), 128))
                          : (_openBlock(), _createElementBlock("i", _hoisted_4))
                      ]),
                      _: 2
                    }, 1032, ["title"]))
                  }), 128))
                ]),
                _: 1
              }, 8, ["onActivateTab"])
            ]),
            _: 1
          })
        ]),
        _: 1
      })
    ]),
    _: 1
  }, 8, ["show"]))
}