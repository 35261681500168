
import { Options, Prop, Vue } from "vue-property-decorator";
import EducationLessonFormatBKF from "@/components/Pages/Education/EducationLessonFormatBKF.vue";
@Options({
  components: {
    EducationLessonFormatBKF,
  },
})
export default class EducationBlockBKF extends Vue {
  @Prop()
  public studentExamAndEducation!: any;
}
