
import { Options, Prop, Vue } from "vue-property-decorator";
import EducationLessonFormat from "@/components/Pages/Education/EducationLessonFormat.vue";

@Options({
  components: {
    EducationLessonFormat,
  },
})
export default class EducationBlock extends Vue {
  @Prop()
  public studentExamAndEducation!: any;

  public formattedLessons(lesson: any) {
    if (lesson && lesson > 9) {
      return `${lesson}`;
    } else if (lesson < 10 && lesson > 0) {
      return `0${lesson}`;
    }
    return 0;
  }

  public formattedMinutes(minutes: any) {
    if (minutes && minutes > 9) {
      return `${minutes}`;
    } else if (minutes < 10 && minutes > 0) {
      return `0${minutes}`;
    }
    return "00";
  }
}
